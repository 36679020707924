<!-- 
USE THE TEST() FUNCTION TO CONSOLE.LOG
    EXAMPLE, ADD: @click="test(word.id)" ON <WORDCARD
-->
<!-- FUTURE MODAL DESIGN: https://www.solodev.com/blog/web-design/how-to-style-your-select-boxes.stml -->
<template>
  <InfoBar
    v-if="words"
    :words="words"
    :searchedWords="searchedWords"
    @clear="clearSearch"
  />
  <div class="menusBG">
    <div class="menuWrapper">
      <div class="mainMenu" @click="toggleMenuBox">
        <h1 class="menuIcon">
          <fa icon="bars" />
        </h1>
      </div>
      <div class="chapterMenu" @click="toggleChapterBox">
        <h1 class="menuIcon" id="chapterIcon">
          CH {{ selectedChapter || 42 }}
        </h1>
      </div>
      <div class="searchMenu" @click="toggleSearchBox">
        <h1 class="menuIcon">
          <fa icon="magnifying-glass" />
        </h1>
      </div>
    </div>
  </div>
  <div class="menuBox" :class="[{ menuBoxVisible: !menuIsVisible }]">
    <div class="navMenu">
      <ul>
        <!-- <li><h1 class="navMenuText">Home</h1></li> -->
        <li>
          <router-link :to="{ name: 'About' }">
            <h1 class="navMenuText">About</h1>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Quizzes' }">
            <h1 class="navMenuText">Quizzes</h1>
          </router-link>
        </li>
        <!-- <li><h1 class="navMenuText">Tests</h1></li> -->
        <li>
          <h1 class="navMenuText">
            <fa class="closeMenu" @click="toggleMenuBox" icon="angle-down" />
          </h1>
        </li>
      </ul>
      <div class="menuFooter" :class="[{ displayHidden: menuIsVisible }]">
        <Social />
      </div>
    </div>
  </div>
  <div class="searchBox" :class="[{ searchBoxVisible: !searchIsVisible }]">
    <div class="searchBoxWrapper">
      <div class="searchWrapper">
        <input
          ref="searchBar"
          class="search"
          :class="[{ displayHidden: searchBarIsVisible }]"
          v-model="searchBar"
          type="text"
          name="search"
          placeholder="Search Latin or English"
          @keyup.enter="toggleSearchBox"
          @keydown.esc="clearSearch"
        />
        <fa
          :class="[{ displayNone: searchBarIsVisible }]"
          class="closeInput"
          icon="xmark"
          @click="clearSearch"
        />
      </div>
      <div :class="[{ displayNone: searchBarIsVisible }]">
        <h4 v-if="searchedWords.length === 1" class="matched">
          <span class="matchedWords">{{ searchedWords.length }}</span> word
          matches your search.
        </h4>
        <h4 v-else-if="searchedWords.length" class="matched">
          There are
          <span class="matchedWords">{{ searchedWords.length }}</span> words in
          this list.
        </h4>
        <h4 v-else class="matched">
          <span class="matchedWords">{{ searchedWords.length }}</span> words
          match your search.
        </h4>
      </div>
      <div :class="[{ displayNone: searchBarIsVisible }]">
        <h1 class="navMenuText">
          <span v-if="searchedWords.length < 498">
            <fa
              class="closeMenu"
              @click="toggleSearchBox"
              icon="angle-double-right"
            />
          </span>
          <span v-else>
            <fa class="closeMenu" @click="toggleSearchBox" icon="angle-up" />
          </span>
        </h1>
      </div>
    </div>
  </div>
  <div class="chapterBox" :class="[{ chapterBoxVisible: !chapterIsVisible }]">
    <div class="chapterBoxDiv">
      <h2 style="margin-bottom: 4px">Tap to Select a Chapter</h2>
      <div
        class="scrollingChapters"
        :class="[{ displayHidden: chapterIsVisible }]"
      >
        <div class="selectChapterH1 y mandatory-scroll-snapping">
          <h1 v-for="chapter in chapters" :key="chapter">
            <!-- https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component -->
            <a class="scroll" @click="scrollMeTo(chapter)"
              >CHAPTER {{ chapter }}</a
            >
          </h1>
        </div>
      </div>
      <h3 style="margin: 4px auto">(Scroll 👆 or 👇)</h3>
      <h1>
        <fa class="closeMenu" @click="toggleChapterBox" icon="angle-down" />
      </h1>
    </div>
  </div>
  <div
    class="words"
    :class="{
      onlyOne: searchedWords.length === 1,
      onlyTwo: searchedWords.length === 2,
    }"
  >
    <div
      v-for="chapter in chapters"
      class="chapterDiv"
      :key="chapter"
      :ref="`chapter_${chapter}_div`"
      :id="'chapter_' + chapter + '_div'"
    >
      <!-- <h1
        v-if="searchedWords.length === words.length"
        :id="'chapter_' + chapter + '_h1'"
        class="chapterH1"
      >
        CHAPTER
        <span v-if="chapter[0] === '0'"> {{ chapter.substring(1) }} </span>
        <span v-else> {{ chapter }} </span>
      </h1> -->
      <div
        v-for="(word, i) in searchedWords"
        :key="word.id"
        :id="word.id + '_searched'"
      >
        <div
          v-if="displaySection(word, i) && word.chapter === chapter"
          class="sectionTitle"
          :id="'section_' + word.section + '_div'"
        >
          <h2
            v-if="word.section"
            style="margin: 5px auto; color: #6a5acd"
            :id="'section_' + word.section + '_h2'"
          >
            SECTION {{ word.section }}
          </h2>
          <h2 v-else style="height: 20px; margin: 0"></h2>
        </div>
        <WordCard
          v-if="word.chapter === chapter"
          class="wordCard"
          :id="word.id"
          :word="word"
        />
      </div>
    </div>
    <div v-if="searchedWords.length < 498">
      <h1 class="clear" @click="clearSearch">Clear Search</h1>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import words from "@/json/vocabFirstYear.json";
import WordCard from "@/components/WordCard.vue";
import InfoBar from "@/components/InfoBar.vue";
import { vocab, data } from "@/store/state";
import Social from "@/components/social/SocialLinks.vue";

export default {
  // 'name' cannot be the same name as the component
  name: "WordView",
  components: {
    // WordCard imported and used in template above
    WordCard,
    InfoBar,
    Social,
  },
  data() {
    return {
      selected: "",
      searchBarIsVisible: true,
      searchIsVisible: true,
      menuIsVisible: true,
      chapterIsVisible: true,
      selectedChapter: null,
    };
  },

  mounted() {
    console.log("'Home' has been mounted");

    // Use $nextTick to ensure refs are populated after DOM updates
    this.$nextTick(() => {
      // Now it's safe to add the scroll event listener and access refs
      window.addEventListener("scroll", this.handleScroll);

      // Log the refs to check if they are populated
      // console.log(this.$refs);
    });
  },

  beforeUnmount() {
    // Clean up the event listener when the component is about to be unmounted
    window.removeEventListener("scroll", this.handleScroll);
  },

  // At some point, add this to "Store" (Vuex)
  created() {
    console.log("Home has been created");
    this.loadWords();
  },

  methods: {
    loadWords() {
      // Assign words and chapters to the store
      data.words = words;
      data.chapters = words
        .map((word) => word.chapter)
        .filter((value, index, self) => self.indexOf(value) === index);

      // Set selectedChapter to the first chapter or default to 42
      this.selectedChapter = words[0]?.chapter || 42; // Use first word's chapter or default to 42
      console.log("Loaded words:", this.words);
    },

    selectChapter(chapter) {
      // Update selectedChapter when a chapter is selected
      this.selectedChapter = chapter;
      console.log("Selected Chapter:", this.selectedChapter);
    },

    scrollMeTo(chapter) {
      let refName = `chapter_${chapter}_div`;
      let element = this.$refs[refName];
      let top = element[0].offsetTop - 10;
      this.selectedChapter = chapter;
      window.scrollTo(0, top);
      this.toggleChapterBox();
    },

    handleScroll() {
      // Loop through each chapter and check if it's at the top of the page
      this.chapters.forEach((chapter) => {
        const chapterElements = this.$refs[`chapter_${chapter}_div`];

        // Ensure the element exists and is a DOM element
        if (chapterElements && chapterElements[0]) {
          const chapterElement = chapterElements[0];
          const rect = chapterElement.getBoundingClientRect();
          // Check if the top of the chapter div is within a small threshold of the viewport
          if (rect.top <= 50 && rect.top >= -50) {
            // Adjust the range as needed
            this.selectedChapter = chapter; // Update selectedChapter
          }
          // else {
          //   console.error(
          //     `Element for chapter ${chapter} not found or not a DOM element.`
          //   );
          // }
        }
      });
    },

    displaySection(w, i) {
      if (!words[i - 1]) {
        return !words[i - 1];
      }
      return w.section !== words[i - 1].section;
    },

    toggleSearchBox() {
      this.searchIsVisible = !this.searchIsVisible;
      this.searchBarIsVisible = !this.searchBarIsVisible;
      this.closeMenuBox();
      this.toggleScroll();
      if (!this.searchIsVisible) {
        this.focusInput();
        document.documentElement.style.overflow = "hidden";
        return;
      } else if (this.searchIsVisible) {
        this.blurInput();
      }
      document.documentElement.style.overflow = "auto";
    },

    toggleChapterBox() {
      this.chapterIsVisible = !this.chapterIsVisible;
      if (!this.chapterIsVisible) {
        // this.focusInput();
        document.documentElement.style.overflow = "hidden";
        return;
      } else if (this.chapterIsVisible) {
        // this.blurInput();
      }
      document.documentElement.style.overflow = "auto";
    },

    toggleMenuBox() {
      this.menuIsVisible = !this.menuIsVisible;
      this.toggleScroll();
    },

    toggleScroll() {
      if (!this.menuIsVisible) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },

    // https://michaelnthiessen.com/set-focus-on-input-vue
    focusInput() {
      this.$refs.searchBar.focus();
    },

    blurInput() {
      this.$refs.searchBar.blur();
    },

    closeMenuBox() {
      if (!this.menuIsVisible) {
        this.menuIsVisible = !this.menuIsVisible;
      }
    },

    clearSearch() {
      if (vocab.search) {
        console.log("Cleared Search");
        vocab.search = "";
      }
    },

    // Use this to test
    test() {
      console.log("TEST");
    },

    // https://www.nightprogrammer.com/vue-js/validate-input-field-by-removing-special-characters-vuejs-example/
    removeSpecialCharacters(chStr) {
      return chStr.replace(/[^\w\s]/gi, "");
    },
  },

  watch: {
    searchBar(newValue) {
      this.searchBar = this.removeSpecialCharacters(newValue);
    },
  },

  computed: {
    words() {
      return data.words;
    },

    chapters() {
      return data.chapters;
    },

    searchBar: {
      get() {
        return vocab.search;
      },
      set(value) {
        vocab.search = value;
      },
    },

    searchFormatted() {
      return this.searchBar.toLowerCase();
    },

    // search tutorial: https://www.youtube.com/watch?v=G34_yNV8FMY
    searchedWords: function () {
      if (this.words) {
        return this.words.filter((word) => {
          return (
            word.latin.toLowerCase().match(this.searchFormatted) ||
            word.english.toLowerCase().match(this.searchFormatted) ||
            word.noMacron.toLowerCase().match(this.searchFormatted)
          );
        });
      } else {
        return this.words;
      }
    },
  },
};
</script>
<style>
.menuFooter {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  background-color: #282060;
  width: 100%;
}

.social {
  margin: 20px;
  padding: 0;
  font-size: 1.75em;
}

.message {
  background-color: #695acdc1;
  margin: 0 auto;
  width: 80%;
  padding: 2px;
  color: white;
}

.chapterDiv {
  width: 100%;
  scroll-behavior: smooth;
}

.chapterH1 {
  position: sticky;
  background-color: #6a5acd;
  color: white;
  font-size: 1.75em;
  padding: 5px 0;
  margin-bottom: 0;
  top: 50px;
}

.chapterSelect {
  border: 1px transparent;
  padding: 0px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(-180deg);
  transition: 0.2s linear;
}

.chapterBoxDiv {
  position: relative;
  top: 25%;
  margin: 10px auto;
  color: white;
}

.scrollingChapters {
  color: #282060;
  background-color: white;
  /* top: 56px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;
  margin: 0 auto;
  width: 20vw;
  min-width: 380px;
}

.selectChapterH1 {
  display: flex;
  overflow: auto;
  flex: none;
}

.selectChapterH1.y {
  width: 40vw;
  min-width: 380px;
  height: 65px;
  flex-flow: column nowrap;
}

.y.mandatory-scroll-snapping {
  scroll-snap-type: y mandatory;
}

.sectionTitle {
  margin-top: 20px;
}

.selectChapterH1 > h1 {
  text-align: center;
  scroll-snap-align: center;
  flex: none;
}

.scroll {
  cursor: pointer;
}

.y.selectChapterH1 > h1 {
  line-height: 65px;
  width: 40vw;
  min-width: 380px;
  height: 100%;
}

.selectChapterH1 > h1:nth-child(even) {
  background-color: #e5e2fc;
}

.selectChapterH1 > h1:nth-child(odd) {
  background-color: #f1f0fd;
}

/* .expanded {
  position: fixed;
  top: 56px;
  z-index: 99;
  height: 45px;
  overflow: auto;
  width: 370px;
  background-color: white;
} */
.clear {
  background-color: #6a5acd;
  color: white;
  padding: 3px 15px;
  border-radius: 10px;
  transform: scale(0.65);
  cursor: pointer;
  border: 1px white solid;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
}

.matched,
.matchedMain {
  font-size: 1.25em;
}

.matchedMain {
  position: fixed;
  width: 100%;
  padding: 10px 0 0 0;
  z-index: 11;
  top: 0px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #6a5acd;
  color: white;
  transition: all 1s;
}

.matchedWords {
  color: #c2b9fa;
  font-weight: 900;
}
</style>
<style scoped>
/* ADD HOVER FX AT THE BOTTOM @MEDIA QUERY */
.infoBar {
  position: relative;
  z-index: 99;
}

.displayHidden {
  opacity: 0;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
  pointer-events: none;
}

.displayNone {
  display: none;
  position: relative;
  transition: all 1s;
}

.modalOverlay {
  z-index: 99;
}

.closeMenu {
  margin: 25px 0;
  padding: 5px 25px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.75);
  color: #282060;
  border: 2px solid white;
  font-size: 0.8em;
  cursor: pointer;
}

.menusBG {
  position: fixed;
  z-index: 12;
  display: flex;
  flex-direction: column;
  margin: auto;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  width: 320px;
  height: 70px;
}

.menuWrapper {
  display: flex;
  justify-content: space-evenly;
}

.mainMenu,
.searchMenu,
.chapterMenu {
  background-color: #282060;
  width: 60px;
  height: 60px;
  border-radius: 75px;
  color: white;
  font-size: 1em;
  border: 1px solid white;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.chapterMenu {
  width: 120px;
}

.menuIcon {
  padding-top: 14%;
}

#chapterIcon {
  font-size: 1.75em;
  padding-top: 10%;
}

.searchBoxWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 350px;
  margin: 0 auto;
}

.searchWrapper {
  position: relative;
  z-index: 0;
  align-items: center;
  width: 80vw;
  max-width: 300px;
  margin: 0 auto;
}

.search {
  align-items: center;
  text-align: left;
  text-indent: 15px;
  width: 70vw;
  max-width: 270px;
  border: 3px solid #ffffff;
  border-radius: 40px;
  margin: 0;
  font-size: 1.25em;
}

.closeInput {
  position: absolute;
  top: 7px;
  right: 12px;
  z-index: 9;
  padding: 8px 10px;
  border-radius: 40px;
}

.search:focus {
  outline: none;
  border: 3px solid #6a5acd;
}

/* .search:focus::placeholder {
  color: transparent;
} */

.searchBox,
.menuBox,
.chapterBox {
  position: fixed;
  z-index: 12;
  align-content: center;
  width: 100%;
  left: 0;
  right: 0;
  background-color: rgba(40, 32, 96, 0.9);
  padding: 0;
  height: 0;
  font-size: 16px;
  margin: 0 auto;
  transition: all 0.3s;
}

.menuBox,
.chapterBox {
  bottom: 0;
}

.searchBox {
  top: 0;
}

.searchBoxVisible,
.menuBoxVisible,
.chapterBoxVisible {
  padding: 10px 0 10px 0;
  height: 100%;
  transition: all 0.3s;
}

.navMenu {
  position: relative;
  top: 15%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 300px;
}

.navMenuText {
  margin: 20px;
  color: white;
  cursor: pointer;
}

.navMenu ul {
  list-style-type: none;
  padding: 0;
}

.words {
  position: absolute;
  z-index: 10;
  top: 33px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 150px;
  box-shadow: 0 0 50px 0px rgba(0, 0, 0, 0.075);
  width: 40vw;
  min-width: 380px;
  margin: 0 auto;
}

.onlyOne {
  margin: 120px auto 0px auto;
  padding-bottom: 20px;
  border-radius: 5px;
}

.onlyTwo {
  margin: 35px auto 0px auto;
  padding-bottom: 20px;
  border-radius: 5px;
}

.more {
  background: white;
  margin-left: 10px;
  padding: 7px 10px;
  border: 1px solid black;
  border-radius: 25px;
  font-weight: 900;
  cursor: pointer;
}

.matched {
  margin: 10px 0;
  color: white;
}

.modal {
  position: sticky;
  z-index: 1;
  top: 270px;
  max-width: 280px;
  min-width: 240;
  background: hsl(248, 53%, 58%, 0.9);
  opacity: 1;
  padding: 20px 25px;
  margin: 100px auto 1000px auto;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
}

.modal-h2 {
  position: relative;
  color: #fff;
  font-size: 1.75em;
  margin: 0px 5px 10px 5px;
}

.close-div {
  display: flex;
}

.modal-close {
  margin-left: auto;
  position: relative;
  background: #fff200;
  font-size: 18px;
  padding: 0 15px;
  border: none;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
  margin-bottom: 10px;
}

.modal-select-group {
  height: 54px;
  margin-bottom: 15px;
  position: relative;
}

.modal-select-group .modal-select {
  width: 100%;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 22px;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 5px 15px;
}

.modal-select-group .modal-select option {
  font-size: 22px;
  background: #fff;
}

.modal-select-group:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 54px;
  width: calc(100% - 54px);
  background: #fff;
  z-index: 0;
  border-radius: 5px 0 0 5px;
}

.modal-select-group:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 54px;
  background-color: #fff200;
  background-image: url(../assets/select1.png);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  border-radius: 0 5px 5px 0;
}

.enter {
  padding: 0 10px;
  color: white;
  background: #6a5acd;
  border-radius: 3px;
  transition-delay: 1s;
}

.overlay {
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  background: hsl(250, 33%, 4%, 0.8);
  height: 100vh;
  width: 100vw;
}

/* 
@media only screen and (orientation: portrait) {
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
} */

@media (min-width: 1200px) {
  .modal {
    padding: 20px 55px;
  }
}

@media (max-width: 350px) {
  .searchWrapper,
  .search {
    margin-top: 5px;
    border-radius: 0;
    width: 100%;
  }
}

/* https://www.geeksforgeeks.org/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices/ */
@media (hover: hover) {
  .mainMenu:hover,
  .searchMenu:hover {
    transform: scale(1.1);
  }

  .navMenuText:hover {
    color: #c2b9fa;
  }

  .clear:hover {
    background-color: whitesmoke;
    color: #6a5acd;
    border: 1px solid #6a5acd;
  }

  .closeInput:hover {
    background-color: lightgray;
  }

  .more:hover,
  .search:hover {
    background-color: #e5e2fc;
  }

  .closeMenu:hover {
    background-color: transparent;
    color: white;
  }

  .scroll:hover {
    color: #6a5acd;
  }

  .chapterSelect:hover {
    border: 1px white solid;
  }
}
</style>
